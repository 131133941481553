#root .runtimes-table .label {
  text-transform: capitalize;
}
#root .header-actions {
  display: flex;
  margin-bottom: 20px;
}
#root .header-actions .form-group {
  margin-bottom: 0px;
}
#root .header-actions > button:first-child {
  margin-right: 10px;
}
#root .local-runtime-server-key-display .alert {
  width: 50%;
}
