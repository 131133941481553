.table {
  width: 100%;
}
.table th {
  text-align: left;
}
.generic-table-caption {
  margin-bottom: 0.5rem;
  text-align: start;
  font-weight: 550;
}
.generic-table-wrapper {
  position: relative;
  width: 100%;
  font-size: 14px;
  margin-bottom: 1rem;
}
.generic-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.generic-header {
  height: 2.4rem;
  padding-left: 0.5rem;
  cursor: inherit;
  background: var(--exo-palette-gray-20);
  text-align: left;
}
.generic-header:hover {
  background: var(--exo-palette-gray-30);
}
.generic-header-start {
  border-top-left-radius: var(--exo-radius-standard);
  border-bottom-left-radius: var(--exo-radius-standard);
}
.generic-header-end {
  border-top-right-radius: var(--exo-radius-standard);
  border-bottom-right-radius: var(--exo-radius-standard);
}
.header-expandable-table {
  width: 25px;
}
.generic-row {
  border-bottom: 2px solid var(--exo-palette-gray-20);
}
.generic-row-tall {
  height: 2.5rem;
}
.highlight {
  background-color: var(--exo-palette-gray-10);
}
.generic-table-wrapper tr.highlight .show-on-highlight {
  display: inline-block;
}
.show-on-highlight {
  display: none;
}
.generic-cell {
  padding: 0.3rem 0.5rem;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  line-height: 1.2rem;
}
.generic-cell:has(img:only-child) {
  text-overflow: unset;
}
.generic-cell-simple {
  padding: 0.3rem 0.5rem;
  white-space: nowrap;
  line-height: 1.2rem;
}
.generic-cell-overflow {
  padding: 0.3rem 0.5rem;
  line-height: 1.2rem;
}
.overflow-ellipsis {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}
.generic-table-wrapper .select-all {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.action-btn-wrapper {
  display: flex;
  justify-content: center;
}
.table-icon {
  width: 2rem;
  height: 2rem;
  border-radius: 0.7rem;
  font-size: 1.4rem;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: var(--exo-color-font);
}
.table-icon-text-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}
.table-icon-large {
  font-size: 1.6rem;
}
.table-icon-medium {
  font-size: 1.2rem;
}
.table-icon-height {
  width: 2rem;
  height: 2rem;
}
.table-icon:hover {
  color: var(--exo-palette-gray-60);
}
.table-icon:disabled {
  color: var(--exo-palette-gray-20);
}
.table-icon:disabled:hover {
  color: var(--exo-palette-gray-20);
}
.table-icon-delete:hover {
  color: var(--exo-palette-red-60);
}
.table-icon:not(:last-child) {
  margin-right: 0.6rem;
}
.table-no-results-message {
  text-align: center;
  width: 100%;
  font-size: 16px;
  padding: 10px 0px 20px;
}
.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination-items-display {
  min-width: 6rem;
}
.pagination-per-page-wrapper {
  margin-top: 1.8rem;
  margin-bottom: 1rem;
}
.pagination-per-page-text {
  margin-right: 0.5rem;
}
.pagination-control-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 20rem;
  margin-top: 1.8rem;
}
.pagination-btns-wrapper {
  display: flex;
}
.pagination-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  width: 2rem;
  height: 2.2rem;
  border-radius: var(--exo-radius-standard);
  border: none;
  background: none;
  margin: 0 0.2rem;
  color: var(--exo-palette-navy-80);
}
.pagination-btn.selected {
  color: white;
  background-color: var(--exo-palette-navy-80);
}
.pagination-btn:hover:not(.selected) {
  background-color: var(--exo-palette-gray-20);
}
.pagination-btn-dots {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 1rem;
  width: 2rem;
  height: 2.1rem;
  border: none;
  background: none;
  margin: 0 -12px;
  color: var(--exo-palette-navy-80);
}
.pagination-icon-btn {
  font-size: 1.6rem;
}
.pagination-icon-btn:hover:not(:disabled) {
  color: var(--exo-palette-gray-60);
}
.pagination-icon-btn:disabled {
  color: var(--exo-palette-gray-20);
}
.generic-table-nested {
  margin: 0;
  border-bottom: 1px solid #dddddd;
  background-color: #fafafa;
}
.generic-table-nested th {
  background: transparent;
  border: none;
}
.within-table-input {
  margin-bottom: calc(var(--exo-spacing-medium) * -1);
}
#root .generic-table-wrapper tr.highlight {
  background: linear-gradient(180deg, #efefef 0%, #e4e4e4 100%);
}
#root .generic-table-wrapper tr.highlight .move-up,
#root .generic-table-wrapper tr.highlight .move-down {
  display: inline-block;
}
#root .generic-row .form-group {
  margin-bottom: 0px;
}
