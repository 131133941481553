@import 'glyphicons.css';
:root {
  --default-font-family: 'Open Sans', sans-serif;
  --default-heading-font-family: 'Poppins', sans-serif;
  --loader-delay-time: 400ms;
  --loader-fade-time: 400ms;
}
.floating-panel {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  border-radius: var(--exo-radius-standard);
  background-color: #ffffff;
}
.capitalize {
  text-transform: capitalize;
}
.lowercase {
  text-transform: lowercase;
}
.alert {
  font-size: 1em !important;
}
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.nowrap {
  white-space: nowrap;
}
.link-emulate {
  border: 0;
  padding: 0;
  background: none;
  color: #127b87;
  cursor: pointer;
  text-align: left;
}
.link-emulate:hover,
.link-emulate:focus {
  text-decoration: underline;
}
.grouped-control {
  display: flex;
}
.grouped-control-input {
  height: var(--exo-spacing-x-large);
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  width: 100%;
}
.grouped-control-extension {
  background: #e7e7e7;
  border-width: 1px;
  border-left-width: 0px;
  border-style: solid;
  border-color: #cccccc;
  border-radius: var(--exo-radius-standard);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.small-control-input {
  display: block;
  width: auto;
  height: var(--exo-spacing-x-large);
  padding: 8px 12px;
  font-size: 15px;
}
.full-height {
  height: 100%;
}
.text-padding {
  padding: 6px 12px;
}
.bold {
  font-weight: bold;
}
.block {
  display: block;
}
.relative {
  position: relative;
}
.flex {
  display: flex;
}
.gap-x-small {
  gap: var(--exo-spacing-x-small);
}
.gap-small {
  gap: var(--exo-spacing-small);
}
.gap-medium {
  gap: var(--exo-spacing-medium);
}
.gap-large {
  gap: var(--exo-spacing-large);
}
.gap-x-large {
  gap: var(--exo-spacing-x-large);
}
.inline-block {
  display: inline-block;
}
.flex-grow {
  flex-grow: 1;
}
.flex-wrap {
  flex-wrap: wrap;
}
.no-shrink {
  flex-shrink: 0;
}
.flex-child-right {
  margin-left: auto;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-end {
  align-self: flex-end;
}
.text-align-center {
  text-align: center;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.column-footer {
  margin-top: auto;
}
.margin-top {
  margin-top: 15px;
}
.margin-top-small {
  margin-top: 5px;
}
.margin-top-large {
  margin-top: 30px;
}
.margin-right {
  margin-right: 15px;
}
.margin-right-sml {
  margin-right: 5px;
}
.margin-left {
  margin-left: 15px;
}
.margin-left-med {
  margin-left: 10px;
}
.margin-left-sml {
  margin-left: 5px;
}
#root .margin-bottom-none {
  margin-bottom: 0px;
}
.margin-bottom {
  margin-bottom: 15px;
}
.margin-bottom-large {
  margin-bottom: 30px;
}
.margin-bottom-small {
  margin-bottom: 5px;
}
.auto-margin-top {
  margin-top: auto;
}
.padding {
  padding: 10px;
}
.padding-top {
  padding: 15px;
}
.padding-left {
  padding-left: 15px;
}
.padding-left-sml {
  padding-left: 5px;
}
.padding-right {
  padding-right: 15px;
}
.no-padding-top {
  padding-top: 0px;
}
.no-padding {
  padding: 0px;
}
.body-text {
  letter-spacing: normal;
  font-size: 12px;
}
.label-above {
  display: block;
  margin-bottom: 4px;
}
.textbox {
  padding: 4px 8px;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.overflow-y-auto {
  overflow-y: auto;
}
.inline-list {
  list-style: none;
  display: flex;
  padding-left: 0;
}
.inline-list li {
  margin-right: 15px;
}
.nowrap {
  white-space: nowrap;
}
details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}
.border-top {
  border-top: 1px solid #ccc;
}
.border-bottom {
  border-bottom: 1px solid #ccc;
}
.padding-default {
  padding: 15px;
}
.center-content {
  display: flex;
  justify-content: center;
}
.flex-end-content {
  display: flex;
  justify-content: flex-end;
}
.hide-empty-labels label:empty {
  display: none;
}
.full-width {
  width: 100%;
}
.width-expand-to-full {
  width: auto;
  max-width: 100%;
}
.modal-dialog.modal-large {
  width: 80%;
}
.hidden {
  visibility: hidden;
}
.no-focus-border {
  outline: none;
}
.keyboard-focusable:focus-visible {
  outline: 2px solid black;
}
.radioSpacing[type='radio'] {
  margin-right: 10px;
}
.vertical-align-top {
  vertical-align: top;
}
.filterHeading {
  background-color: #f5f5f5;
  border-radius: var(--exo-radius-standard);
}
.no-line-height {
  line-height: 0px;
}
.cursor-text {
  cursor: text;
}
.rotate180 {
  transform: rotate(180deg);
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
}
.list-inline > li {
  display: inline-block;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
}
.container-fluid:before {
  display: table;
  content: ' ';
}
.panel-heading {
  padding: 10px 15px;
  border-bottom: 0;
  border-top-left-radius: var(--exo-radius-standard);
  border-top-right-radius: var(--exo-radius-standard);
}
#root .hidden {
  display: none;
  visibility: hidden;
}
.center {
  margin: 0 auto;
}
.beta-tag {
  font-family: var(--exo-font-family);
  font-size: var(--exo-font-size-small);
  font-weight: var(--exo-font-weight-semi-bold);
  line-height: var(--exo-line-height-denser);
  padding: 0 var(--exo-spacing-2x-small);
  color: var(--exo-palette-coral-50);
  border: 2px solid currentColor;
  border-radius: var(--exo-radius-standard);
}
.view-enter {
  opacity: 0.01;
  transition: opacity 0.3s ease-in;
}
.view-enter.view-enter-active {
  opacity: 1;
}
.view-leave {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.view-leave.view-leave-active {
  opacity: 0.01;
}
.slide-forward-enter {
  transform: translateX(100%);
  transition: transform 1s ease;
}
.slide-forward-enter.slide-forward-enter-active {
  transform: translateX(0);
}
.slide-forward-leave {
  transform: translateX(0);
  transition: transform 1s ease;
}
.slide-forward-leave.slide-forward-leave-active {
  transform: translateX(-100%);
}
.slide-back-enter {
  transform: translateX(-100%);
  transition: transform 1s ease;
}
.slide-back-enter.slide-back-enter-active {
  transform: translateX(0);
}
.slide-back-leave {
  transform: translateX(0);
  transition: transform 1s ease;
}
.slide-back-leave.slide-back-leave-active {
  transform: translateX(100%);
}
html,
body,
#root,
.draw {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  margin: 0px;
}
ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
}
a {
  color: #337ab7;
  text-decoration: none;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
  height: 0;
  box-sizing: content-box;
}
#root * {
  box-sizing: border-box;
}
.draw {
  display: grid;
  grid-template-areas: 'header header' 'sidebar tabs' 'footer footer';
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr min-content;
}
.sidebar {
  grid-area: sidebar;
}
.tab-outer {
  grid-area: tabs;
  overflow: hidden;
}
.footer {
  grid-area: footer;
}
.wait-container {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 64px;
  background-color: rgba(255, 255, 255, 0.5);
  animation: fadein forwards var(--loader-fade-time) var(--loader-delay-time);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wait-container.small {
  background-color: transparent;
  margin-right: 5px;
  min-height: auto;
  position: relative;
}
.wait-container.small .wait-message {
  margin-top: 0;
}
.wait-container.small .wait-spinner {
  width: 16px;
  height: 16px;
  animation: 750ms linear 0s infinite spin;
}
.wait-message {
  opacity: 0;
  margin-top: 1em;
  font-size: 1em;
  animation: fadein forwards var(--loader-fade-time) var(--loader-delay-time);
}
/* outer */
.wait-spinner {
  opacity: 0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 4px solid #cecece;
  border-top-color: #acacac;
  animation: 750ms linear 0s infinite spin, fadein forwards var(--loader-fade-time) var(--loader-delay-time);
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.navbar-inverse {
  border-radius: 6px;
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  background-color: #222;
  border-color: #080808;
}
.navbar-inverse .navbar-nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  float: left;
  margin: 0;
}
.navbar-inverse .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: var(--exo-radius-standard);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.navbar-inverse .navbar-toggle {
  display: none;
}
.navbar-inverse .navbar-collapse {
  width: auto;
  border-top: 0;
  box-shadow: none;
  padding-right: 15px;
  padding-left: 15px;
  overflow-x: visible;
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}
.navbar-inverse .navbar-collapse.collapse {
  display: block;
  height: auto;
  padding-bottom: 0;
  overflow: visible;
  visibility: visible;
}
.navbar-inverse .navbar-nav > li {
  float: left;
  position: relative;
  display: block;
}
.navbar-inverse .navigation-item {
  white-space: nowrap;
}
.navbar-inverse .dropdown-submenu {
  position: relative;
}
.navbar-inverse .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.nav-tabs {
  border-bottom: 1px solid #ddd;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-tabs > li {
  margin-bottom: -1px;
  position: relative;
  display: inline-flex;
}
.navbar:not(.navbar-editor) {
  background-color: #ffffff;
  border-color: #ffffff;
  font-size: 1.3em !important;
}
.navbar:not(.navbar-editor) .navbar-header {
  display: none;
}
.navbar:not(.navbar-editor) .top-nav-element > a {
  padding-left: 0.25em;
  padding-right: 0.25em;
  color: #10639b !important;
}
.navbar:not(.navbar-editor) .top-nav-element:not(.disabled) > a::after {
  content: '>';
  margin-left: 0.5em;
}
.navbar:not(.navbar-editor) .top-nav-element.active > a {
  color: #cccccc !important;
}
.navbar:not(.navbar-editor) .top-nav-element > a:hover {
  background: transparent !important;
}
.navbar:not(.navbar-editor) .top-nav-element.disabled > a {
  color: #212121 !important;
}
.draw .tabs .popover {
  position: absolute;
}
html {
  font-size: 16px;
}
body {
  font-family: var(--default-font-family);
  color: #212121;
  line-height: 1.25em;
  font-size: 13px;
}
p {
  line-height: 1.5em;
}
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: var(--default-heading-font-family);
  color: #212121;
}
h1,
.h1 {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 2.375rem;
}
h2,
.h2 {
  font-size: 1.5625rem;
  font-weight: 400;
  line-height: 2.125rem;
}
h3,
.h3 {
  font-size: 1.4375rem;
  font-weight: 400;
  line-height: 2rem;
}
h4,
.h4 {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6875rem;
}
h5,
.h5 {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5rem;
}
.quiet {
  font-size: 12px;
  color: #666;
}
.strong {
  font-weight: 700;
}
#root .search-input-button {
  height: auto;
  min-height: var(--exo-spacing-x-large);
  word-break: break-word;
  border-top-right-radius: var(--exo-radius-standard);
  border-bottom-right-radius: var(--exo-radius-standard);
}
#root .outcome {
  margin-right: 0;
}
#root .btn {
  font: var(--exo-text-body-large);
  height: var(--exo-spacing-x-large);
  border-radius: var(--exo-radius-standard);
  padding: 0px 12px;
  font-weight: 600;
  font-size: 14px;
  display: inline-flex;
  gap: 0.6rem;
  align-items: center;
  justify-content: center;
  transition: background-color 200ms ease;
}
#root .btn svg {
  font-size: 1.3rem;
}
#root .btn:focus-visible {
  outline-offset: -3px;
  outline: var(--exo-spacing-3x-small) solid var(#fff);
}
#root .btn:active {
  box-shadow: none;
}
#root .btn .glyphicon:last-child,
#root .btn .caret {
  margin-left: 5px;
}
#root .btn .glyphicon {
  top: 0;
}
#root .btn .glyphicon::before {
  line-height: 15px;
}
#root .btn-icon {
  border: 0;
  background: none;
  color: var(--exo-color-background-action);
  min-height: 26px;
  min-width: 26px;
}
#root .btn-icon:hover {
  color: var(--exo-color-background-action-hover);
}
#root .btn-icon.danger:hover {
  color: var(--exo-color-background-danger-strong);
}
#root .btn-icon:disabled {
  color: #ccc;
}
#root .input-group-btn .btn,
#root .form-control + .btn {
  height: auto;
  min-height: var(--exo-spacing-x-large);
  border-color: #ccc;
}
#root .btn-inline-icon {
  height: auto;
  min-height: var(--exo-spacing-x-large);
  outline: none;
  border: none;
  padding: 0 5px;
  background: transparent;
}
#root .btn-default,
#root .btn-outline,
#root .btn.btn-outline,
#root .btn-default.btn-outline,
#root .btn-info {
  background-color: var(--exo-color-background-action-secondary);
  border: 1px solid var(--exo-color-border);
  color: var(--exo-color-background-action);
}
#root .btn-default:hover,
#root .btn-outline:hover,
#root .btn.btn-outline:hover,
#root .btn-default.btn-outline:hover,
#root .btn-info:hover,
#root .btn-default:active,
#root .btn-outline:active,
#root .btn.btn-outline:active,
#root .btn-default.btn-outline:active,
#root .btn-info:active,
#root .btn-default:focus,
#root .btn-outline:focus,
#root .btn.btn-outline:focus,
#root .btn-default.btn-outline:focus,
#root .btn-info:focus {
  background-color: var(--exo-color-background-action-hover-weak);
  border-color: var(--exo-color-background-action-hover);
  color: var(--exo-color-background-action-hover);
}
#root .btn-success,
#root .btn-primary {
  background-color: var(--exo-color-background-selected);
  border: 1px solid var(--exo-color-background-selected);
  color: #fff;
}
#root .btn-success:hover,
#root .btn-primary:hover,
#root .btn-success:active,
#root .btn-primary:active,
#root .btn-success:focus,
#root .btn-primary:focus {
  background-color: var(--exo-color-background-selected-hover);
  border-color: var(--exo-color-background-selected-hover);
  color: #fff;
}
#root .btn-danger {
  background-color: var(--exo-color-background-danger-strong);
  color: var(--exo-color-font-inverse);
  border: 1px solid var(--exo-color-background-danger-strong);
}
#root .btn-danger:hover,
#root .btn-danger:active,
#root .btn-danger:focus {
  background-color: var(--exo-color-background-danger-strong-hover);
  border-color: var(--exo-color-background-danger-strong-hover);
}
#root .btn-link,
#root .btn-link:hover,
#root .btn-link:active,
#root .btn-link:focus {
  border: 0;
  background: transparent;
  display: inline;
  padding: 0;
  outline: 0;
}
#root .btn.btn[disabled] {
  background: #e5e5e5;
  border: solid 1px #e5e5e5;
  color: #666666;
  cursor: default;
}
#root :not(.btn-group) .btn + .btn {
  margin-left: 12px;
}
#root .btn-group .btn + .btn {
  margin-left: 0px;
}
#root .btn-group .btn {
  border-radius: 0px;
}
#root .btn-group .btn:first-child {
  border-top-left-radius: var(--exo-radius-standard);
  border-bottom-left-radius: var(--exo-radius-standard);
}
#root .btn-group .btn:last-child {
  border-top-right-radius: var(--exo-radius-standard);
  border-bottom-right-radius: var(--exo-radius-standard);
}
#root .btn-no-appearance {
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
}
#root .btn-no-appearance-left {
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  text-align: left;
}
#root .select-all {
  user-select: all;
}
#root .btn-wrapper {
  width: 100%;
  background: transparent;
}
#root .table-container > label {
  font-size: 12px !important;
}
#root .mw-toggle input:checked + div {
  background: #008cba;
}
#root .mw-toggle input:focus + div {
  box-shadow: 0 0 1px #008cba;
}
#root .mw-toggle input:checked + div.success {
  background: #43ac6a;
}
#root .mw-toggle input:focus + div.success {
  box-shadow: 0 0 1px #43ac6a;
}
#root .mw-toggle input:checked + div.info {
  background: #31b0d5;
}
#root .mw-toggle input:focus + div.info {
  box-shadow: 0 0 1px #31b0d5;
}
#root .mw-toggle input:checked + div.warning {
  background: #b67102;
}
#root .mw-toggle input:focus + div.warning {
  box-shadow: 0 0 1px #b67102;
}
#root .mw-toggle input:checked + div.danger {
  background: #d32a0e;
}
#root .mw-toggle input:focus + div.danger {
  box-shadow: 0 0 1px #d32a0e;
}
#root .mw-toggle.has-error > div > label,
#root .mw-content.has-error > .mce-container {
  border: 1px solid #f04124;
}
#root .navbar-wizard .steps > li {
  color: #43ac6a;
}
#root .navbar-wizard .steps > li > .glyphicon {
  background-color: #43ac6a;
}
#root .navbar-wizard .steps > li > .indicator {
  background-color: #43ac6a;
}
#root .navbar-wizard .steps > li .indicator:before,
#root .navbar-wizard .steps > li .indicator:after {
  background-color: #43ac6a;
}
#root .navbar-wizard .steps > li.active {
  color: #008cba;
}
#root .navbar-wizard .steps > li.active > .indicator {
  background-color: #008cba;
}
#root .navbar-wizard .steps > li.active .indicator:before,
#root .navbar-wizard .steps > li.active .indicator:after {
  background-color: #008cba;
}
#root .navbar-wizard .steps > li.active ~ li {
  color: #888888;
}
#root .navbar-wizard .steps > li.active ~ li > .indicator {
  background-color: #bbbbbb;
}
#root .navbar-wizard .steps > li.active ~ li .indicator:before,
#root .navbar-wizard .steps > li.active ~ li .indicator:after {
  background-color: #bbbbbb;
}
#root .btn-group .dropdown-menu {
  margin-top: 0px;
}
#root .mw-pagination > .btn:first-child {
  margin-right: 0;
}
#root .mw-tiles-item-container .back {
  transform: none !important;
}
#root .mw-tiles-item-footer {
  display: none;
}
#root .mw-tiles-item-outcomes {
  padding: 15px;
  justify-content: flex-start;
}
#root .mw-tiles-item-outcomes .outcome {
  border-radius: inherit;
}
#root .copyable-icon {
  font-size: 18px;
  color: black;
}
#root .btn-action {
  display: inline-grid;
  align-items: center;
  justify-items: center;
  line-height: 1;
  aspect-ratio: 1 / 1;
  border-radius: var(--exo-radius-standard);
  transition: background-color var(--exo-time-fast) var(--exo-curve-smooth), color var(--exo-time-fast) var(--exo-curve-smooth), outline var(--exo-time-x-fast) var(--exo-curve-smooth), outline-offset var(--exo-time-x-fast) var(--exo-curve-smooth);
}
#root .btn-action.btn-primary {
  border: 0;
  background-color: var(--exo-palette-navy-80);
  color: var(--exo-palette-white);
}
#root .btn-action.btn-primary:hover {
  background-color: var(--exo-palette-navy-60);
}
#root .btn-action.btn-primary:active {
  outline: 1px solid var(--exo-palette-white);
  outline-offset: -4px;
}
#root .btn-action.btn-primary:disabled,
#root .btn-action.btn-primary:disabled:hover,
#root .btn-action.btn-primary:disabled:active {
  color: var(--exo-palette-gray-30);
  background-color: var(--exo-palette-gray-20);
}
#root .btn-action.btn-secondary {
  border: 1px solid var(--exo-palette-navy-80);
  background-color: transparent;
  color: var(--exo-palette-navy-80);
}
#root .btn-action.btn-secondary:hover {
  background-color: var(--exo-palette-aqua-30);
}
#root .btn-action.btn-secondary:active {
  outline: 1px solid var(--exo-palette-navy-80);
  outline-offset: -4px;
}
#root .btn-action.btn-secondary:disabled,
#root .btn-action.btn-secondary:disabled:hover,
#root .btn-action.btn-secondary:disabled:active {
  color: var(--exo-palette-gray-30);
  border: 1px solid var(--exo-palette-gray-30);
  background-color: transparent;
}
#root .btn-action.btn-tertiary {
  border: 0;
  background-color: transparent;
  color: var(--exo-palette-navy-80);
}
#root .btn-action.btn-tertiary:hover {
  background-color: var(--exo-palette-aqua-30);
}
#root .btn-action.btn-tertiary:active {
  outline: 1px solid var(--exo-palette-navy-80);
  outline-offset: -4px;
}
#root .btn-action.btn-tertiary:disabled,
#root .btn-action.btn-tertiary:disabled:hover,
#root .btn-action.btn-tertiary:disabled:active {
  color: var(--exo-palette-gray-30);
  background-color: transparent;
}
#root .btn-action.danger:hover {
  color: var(--exo-palette-white);
  background-color: var(--exo-palette-red-60);
}
#root .btn-action.danger:active {
  outline: 1px solid var(--exo-palette-white);
  outline-offset: -4px;
}
#root .btn-action.is-activated {
  background-color: var(--exo-palette-aqua-30);
  outline: 1px solid var(--exo-palette-navy-80);
  outline-offset: -4px;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
#root .form-control {
  display: block;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border-radius: var(--exo-radius-standard);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  width: 100%;
  height: var(--exo-spacing-x-large);
  padding: 0.4rem 0.8em;
  border: 1px solid #ccc;
}
#root .form-control.with-extension {
  border-radius: var(--exo-radius-standard);
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
#root .form-control[size] {
  max-width: 100%;
  min-width: 4em;
  width: auto;
}
#root .form-control[disabled] {
  background-color: #eee;
  opacity: 1;
}
#root .form-control-with-icon {
  display: flex;
  align-items: center;
  padding: 1px;
}
#root .form-control-input {
  width: 100%;
  height: 100%;
  border: none;
  padding: 0.4rem 0.8em;
}
#root textarea.form-control {
  height: auto;
}
#root .form-control-exo {
  width: 350px;
}
#root .search-input-text {
  height: var(--exo-spacing-x-large);
}
#root label,
#root span.label,
#root .control-label,
#root .help-block,
#root .checkbox,
#root .radio {
  font-size: 12px;
  font-weight: normal;
}
#root .help-block {
  margin-top: 0.25em;
  margin-bottom: 1.5em;
  display: block;
  color: #757575;
}
#root .checkbox label {
  line-height: 22px;
  display: flex;
  align-items: center;
}
#root input[type='checkbox']:not(:disabled):hover,
#root label input[type='checkbox']:not(:disabled) + span {
  cursor: pointer;
}
#root label,
#root span.label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
}
#root label input[type='checkbox'] {
  margin-right: 6px;
  margin-top: 0;
  vertical-align: -2px;
}
#root .form-group {
  margin-bottom: 15px;
}
#root .help-block.error-state {
  color: red;
}
#root .form-footer {
  text-align: right;
  padding: 15px 15px 0 15px;
  margin: 0 -15px;
  border-top: 1px solid var(--exo-palette-gray-20);
}
#root .form-control-width {
  max-width: 300px;
}
#root .form-control-long {
  width: 600px;
}
#root .form-control-short {
  max-width: 80px;
}
#root .form-control-dynamic {
  min-width: 300px;
  width: auto;
}
#root .auto-width {
  width: auto;
}
#root .form-control-textarea {
  max-width: 600px;
  min-height: 150px;
}
#root .has-error .form-control,
#root .has-error [class$='control'] {
  border-color: red;
}
#root .has-error .picker {
  border-color: red;
}
#root .input-required {
  color: red;
}
.toast-notifications {
  list-style: none;
  padding: 0;
  position: fixed;
  bottom: calc(82px + var(--exo-spacing-small));
  right: var(--exo-spacing-small);
}
.macro-editor-container .toast-notifications,
.players .toast-notifications {
  bottom: 14px;
}
.toast-notification {
  padding: 12px;
  width: 300px;
  margin-top: 20px;
  border-radius: var(--exo-radius-standard);
  color: var(--exo-palette-gray-90);
  display: flex;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  word-break: break-word;
}
.toast-notification.toast-wide {
  width: 800px;
}
.toast-notification.error {
  background-color: var(--exo-palette-red-20);
  border-color: var(--exo-palette-red-20);
}
.toast-notification.warning {
  background-color: var(--exo-palette-yellow-20);
  border-color: var(--exo-palette-yellow-20);
}
.toast-notification.info {
  background-color: var(--exo-palette-aqua-30);
  border-color: var(--exo-palette-aqua-30);
}
.toast-notification.success {
  background-color: var(--exo-palette-green-20);
  border-color: var(--exo-palette-green-20);
}
.toast-notification .toast-notification-message {
  margin: auto 0;
}
.toast-notification img {
  margin-right: 16px;
}
.example-enter {
  opacity: 0.01;
  transform: translateY(10px);
}
.example-enter.example-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: 600ms;
}
.example-leave {
  opacity: 1;
  transform: translateY(0px);
}
.example-leave.example-leave-active {
  opacity: 0.01;
  transform: translateY(10px);
  transition: 300ms;
}
.toast-notification-dismiss {
  background: none;
  border: none;
  align-self: flex-start;
  font-size: 20px;
  line-height: 24px;
  margin-top: -3px;
  margin-left: auto;
}
@keyframes notification {
  0% {
    opacity: 0;
    transform: translateX(150%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/* System Flows and deprecated notifications - Begin */
#root .alert {
  padding: 15px;
  margin-bottom: 21px;
  border: 1px solid transparent;
  border-radius: 0;
}
#root .alert-danger {
  background-color: var(--exo-palette-red-20);
  border-color: var(--exo-palette-red-20);
  color: var(--exo-palette-gray-90);
}
#root .alert-warning {
  background-color: var(--exo-palette-yellow-20);
  border-color: var(--exo-palette-yellow-20);
  color: var(--exo-palette-gray-90);
}
#root .alert-info {
  background-color: var(--exo-palette-aqua-30);
  border-color: var(--exo-palette-aqua-30);
  color: var(--exo-palette-gray-90);
}
#root .alert-success {
  background-color: var(--exo-palette-green-20);
  border-color: var(--exo-palette-green-20);
  color: var(--exo-palette-gray-90);
}
#root .alert-dismissable {
  padding-right: 35px;
}
#root .alert-dismissable .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}
#root .flow-container .alert-dismissible .close {
  right: 0px;
}
/* System Flows - End */
#root .graph-tooltip-arrow {
  z-index: -1;
}
#root .authorization-config .search-form-wrapper.active,
#root .collaboration-user:hover,
#root .collaboration-user-more,
#root .tooltip-content,
#root .wait-container,
#root .modal-backdrop {
  z-index: 1;
}
#root .graph-tooltip,
#root .dropdown-list-wrapper {
  z-index: 2;
}
#root .modal-above-TinyMCE {
  z-index: 1301;
}
#root .toast-notifications {
  z-index: 1302;
}
#root .no-zindex {
  z-index: 0;
}
#root .tox-tinymce {
  isolation: isolate;
}
